#homepage-medicine {
    a {
        &:hover {
            color: $color-med;
        }
    }

    .ps-product {
        .ps-product__actions {
            li {
                a {
                    &:hover {
                        color: #fff;
                        background-color: $color-med;
                    }
                }
            }
        }
    }

    .ps-link--concern {
        display: block;
        text-align: center;
        margin-bottom: 10px;

        span {
            display: inline-block;
            min-width: 40px;
            height: 40px;
        }

        strong {
            display: block;
            font-weight: 600;
        }

        &:hover {
            color: $color-med;
        }

        @media screen and (min-width: 768px) {
            display: flex;
            align-items: center;
            margin-bottom: 0;
            span {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 60px;
                height: 60px;
            }
        }
    }

    .ps-home-medicine-concerns {
        margin-bottom: 40px;
        @media screen and (min-width: 1200px) {
            margin-bottom: 70px;
        }
    }

    .ps-medicine-banner {
        margin-bottom: 40px;

        .ps-collection {
            margin-bottom: 30px;
        }

        .ps-block--site-features {
            background-color: #fff;

            .ps-block__item {
                padding-top: 22px;
                padding-bottom: 22px;
            }

            .ps-block__left {
                i {
                    color: $color-med;
                }
            }
        }
    }

    .ps-medicine--section {
        padding: 20px;
        border-radius: 4px;
        background-color: #fff;

        .ps-section__header {
            padding-bottom: 30px;

            h3 {
                font-weight: 400;
            }
        }

        @media screen and (min-width: 480px) {
            .ps-section__header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 30px;

                h3 {
                    font-weight: 400;
                }
            }
        }

        @media screen and (min-width: 1200px) {
            .ps-section__header {
                padding-bottom: 40px;
            }

            padding: 38px;
        }
    }

    .ps-medicine-promotion {
        margin-bottom: 50px;
        @media screen and (min-width: 1200px) {
            margin-bottom: 70px;
        }
    }

    .ps-section__links {
        display: inline-block;

        li {
            display: inline-block;
            vertical-align: top;
            margin-right: 30px;

            a {
                color: $color-text;

                &:hover {
                    color: $color-1st;
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .ps-medicine-brands {
        .ps-block--medicine-brand {
            margin-bottom: 50px;
        }

        .ps-section__content {
            display: flex;
            flex-flow: row wrap;
            margin-bottom: -50px;

            > * {
                flex-basis: 100%;
                max-width: 50%;
            }
        }

        @media screen and (min-width: 768px) {
            .ps-section__content {
                > * {
                    max-width: 33.33333%;
                }
            }
        }

        @media screen and (min-width: 992px) {
            .ps-section__content {
                > * {
                    max-width: 20%;
                }
            }
        }
    }

    .ps-deal-of-day {
        margin-bottom: 40px;

        .ps-product .ps-product__price {
            color: #690;
            font-size: 18px;
            font-weight: 600;

            del {
                font-size: 0.8em;
                color: red;
                margin-right: 10px;
            }

            small {
                color: red;
            }
        }

        @media screen and (min-width: 1200px) {
            margin-bottom: 70px;
        }
    }

    .ps-medicine-brands,
    .ps-medicine-testimonials {
        height: 100%;
    }

    .ps-medicine-brands-and-testimonial {
        margin-bottom: 50px;
    }

    .ps-medicine-top-categories {
        .ps-block--category {
            position: relative;
            border-color: transparent;

            p {
                color: $color-heading;
            }

            &:hover {
                border-color: $color-med;

                p {
                    color: $color-med;
                }
            }
        }

        .ps-section__column {
            padding: 0 10px;
        }

        .ps-section__content {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            align-items: center;
            margin: 0 -10px;

            > * {
                flex-basis: 100%;
                max-width: 50%;
            }
        }

        @media screen and (min-width: 768px) {
            .ps-section__content {
                > * {
                    max-width: calc(100% / 4);
                }
            }
        }

        @media screen and (min-width: 1200px) {
            .ps-section__content {
                > * {
                    max-width: calc(100% / 7);
                }
            }
        }
    }

    .ps-medicine-description {
        padding: 40px 0;

        h4 {
            margin-bottom: 30px;
        }

        p {
            margin-bottom: 20px;
        }

        ul {
            margin: 0 0 30px;
            padding: 0;

            li {
                list-style-type: none;
                color: $color-heading;

                &:before {
                    content: '-';
                    margin-right: 1em;
                }
            }
        }

        figure {
            margin-bottom: 30px;

            figcaption {
                font-weight: 600;
                margin-bottom: 10px;
            }
        }

        @media screen and (min-width: 1200px) {
            padding: 80px 0;
        }
    }

    .ps-main--medicine {
        padding: 26px 0;
        background-color: #eee;

        .ps-medicine-top-categories {
            margin-bottom: 40px;
        }

        @media screen and (min-width: 1366px) {
            .ps-medicine-top-categories {
                margin-bottom: 70px;
            }
        }
    }

    .ps-footer--medicine {
        background-color: #fff;

        .form-group--nest {
            margin-bottom: 10px;
        }

        .ps-footer__widgets {
            display: block;

            > * {
                max-width: 100%;
                flex-basis: 100%;
            }
        }

        @media screen and (min-width: 1200px) {
            .ps-footer__widgets {
                display: flex;
            }
        }
        
        @media screen and (min-width: 1440px) {
            .ps-footer__widgets {
                display: flex;
                flex-flow: row nowrap;

                > * {
                    flex-basis: 100%;
                }

                .ps-footer__left {
                    max-width: 420px;
                }
            }
        }
    }

    .ps-medicine-subscribe {
        margin-bottom: 40px;
        padding: 20px 20px;

        .ps-section__right {
            display: flex;
            justify-content: space-between;

            img {
                max-height: 50px;
            }
        }

        .ps-form--medicine-subscribe {
            h3 {
                font-weight: 600;
                font-size: 24px;
            }

            .form-group {
                position: relative;
                display: flex;

                text-transform: uppercase;

                button {
                    @include vertical-align();
                    right: 10px;
                    border: none;
                    color: $color-med;
                    background-color: transparent;
                    text-transform: uppercase;
                    font-weight: 600;
                }

                input {
                    border: none;
                    padding-left: 0;
                    background-color: #fff;
                    padding-left: 10px;
                    border-bottom: 1px solid #eaeaea;

                    &:focus {
                        background-color: transparent;
                        border-bottom-color: $color-med;
                    }
                }
            }

            .ps-form__left {
                margin-bottom: 30px;
            }
        }

        @media screen and (max-width: 1199px) {
            background: #fff !important;
        }

        @media screen and (min-width: 1200px) {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            padding: 30px 45px;
            margin-bottom: 70px;
            .ps-form--medicine-subscribe {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                max-width: 650px;

                h3 {
                    font-size: 30px;
                }

                > * {
                    flex-basis: 100%;
                }

                .form-group {
                    input {
                        background-color: transparent;
                    }
                }

                .ps-form__left {
                    max-width: 160px;
                    margin-bottom: 0;
                }

                .ps-form__right {
                    padding-left: 30px;
                }
            }

            .ps-section__right {
                max-width: 200px;
                display: block;

                a {
                    display: block;
                    margin-bottom: 20px;
                    text-align: center;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .ps-section__left {
                flex-basis: 100%;
            }
        }

        @media screen and (min-width: 1440px) {
            padding: 60px 90px;
            .ps-form--medicine-subscribe {
                .ps-form__right {
                    padding-left: 90px;
                }
            }
        }
    }

    .ps-medicine--recommended-products {
        margin-bottom: 40px;
        @media screen and (min-width: 1200px) {
            margin-bottom: 70px;
        }
    }

    .ps-footer--medicine {
        .widget_contact-us {
            .widget_content {
                h3 {
                    color: $color-med;
                }
            }
        }

        .ps-form--newletter {
            button {
                background-color: $color-med;
                color: #fff;

                &:hover {
                    background-color: darken($color-med, 5%);
                    color: #fff;
                }
            }
        }
    }
}
