.header--medicine .header__extra span {
  background-color: #28afb1;
}

.header--medicine .header__extra span i {
  color: #fff;
}

.header--medicine .header__left p strong {
  color: #ec0101 !important;
}

.header--medicine .header__left p a {
  font-weight: 500;
  text-decoration: underline;
}

.header--medicine .header__left p a:hover {
  color: #28afb1;
}

.header--medicine .ps-form--quick-search {
  max-width: 100%;
  width: 100%;
}

.header--medicine .ps-form--quick-search .form-control {
  background-color: #f9f9f9;
}

.header--medicine .ps-form--quick-search button {
  color: #fff;
  background-color: #28afb1;
}

.header--medicine .ps-block--header-hotline p strong {
  color: #28afb1;
}

.header--medicine .ps-block--user-header a:hover {
  color: #28afb1;
}

.header--medicine .ps-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header--medicine .ps-container > * {
  flex-basis: 100%;
}

.header--medicine .ps-container .header__content-center {
  max-width: 100%;
}

.header--medicine .ps-container .header__content-right {
  max-width: 500px;
}

.header--medicine .navigation {
  background-color: #fff;
  border-bottom: 1px solid #eaeaea;
}

.header--medicine .menu--medicine {
  position: relative;
}

.header--medicine .menu--medicine a:hover {
  color: #28afb1 !important;
}

.header--medicine .menu--medicine .has-mega-menu {
  position: static;
}

.header--medicine .menu--product-categories li:hover {
  background-color: #28afb1;
}

.header--medicine .menu--product-categories li:hover a {
  color: #fff;
}

.header--medicine .mega-menu {
  max-width: 960px;
  width: 100%;
  flex-flow: row wrap;
  align-items: flex-start;
}

.header--medicine .mega-menu > * {
  max-width: 25%;
  flex-basis: 100%;
}

.header--medicine.header--sticky .ps-logo {
  display: inline-block;
}

@media screen and (max-width: 1365px) {
  .header--medicine .ps-block--header-hotline {
    display: none;
  }
  .header--medicine .ps-container .header__content-right {
    max-width: 500px;
  }
}

.header--mobile-medicine {
  background-color: #fff;
}

.header--mobile-medicine .navigation--mobile {
  background-color: #fff !important;
}

#homepage-medicine a:hover {
  color: #28afb1;
}

#homepage-medicine .ps-product .ps-product__actions li a:hover {
  color: #fff;
  background-color: #28afb1;
}

#homepage-medicine .ps-link--concern {
  display: block;
  text-align: center;
  margin-bottom: 10px;
}

#homepage-medicine .ps-link--concern span {
  display: inline-block;
  min-width: 40px;
  height: 40px;
}

#homepage-medicine .ps-link--concern strong {
  display: block;
  font-weight: 600;
}

#homepage-medicine .ps-link--concern:hover {
  color: #28afb1;
}

@media screen and (min-width: 768px) {
  #homepage-medicine .ps-link--concern {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
  #homepage-medicine .ps-link--concern span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
  }
}

#homepage-medicine .ps-home-medicine-concerns {
  margin-bottom: 40px;
}

@media screen and (min-width: 1200px) {
  #homepage-medicine .ps-home-medicine-concerns {
    margin-bottom: 70px;
  }
}

#homepage-medicine .ps-medicine-banner {
  margin-bottom: 40px;
}

#homepage-medicine .ps-medicine-banner .ps-collection {
  margin-bottom: 30px;
}

#homepage-medicine .ps-medicine-banner .ps-block--site-features {
  background-color: #fff;
}

#homepage-medicine .ps-medicine-banner .ps-block--site-features .ps-block__item {
  padding-top: 22px;
  padding-bottom: 22px;
}

#homepage-medicine .ps-medicine-banner .ps-block--site-features .ps-block__left i {
  color: #28afb1;
}

#homepage-medicine .ps-medicine--section {
  padding: 20px;
  border-radius: 4px;
  background-color: #fff;
}

#homepage-medicine .ps-medicine--section .ps-section__header {
  padding-bottom: 30px;
}

#homepage-medicine .ps-medicine--section .ps-section__header h3 {
  font-weight: 400;
}

@media screen and (min-width: 480px) {
  #homepage-medicine .ps-medicine--section .ps-section__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;
  }
  #homepage-medicine .ps-medicine--section .ps-section__header h3 {
    font-weight: 400;
  }
}

@media screen and (min-width: 1200px) {
  #homepage-medicine .ps-medicine--section {
    padding: 38px;
  }
  #homepage-medicine .ps-medicine--section .ps-section__header {
    padding-bottom: 40px;
  }
}

#homepage-medicine .ps-medicine-promotion {
  margin-bottom: 50px;
}

@media screen and (min-width: 1200px) {
  #homepage-medicine .ps-medicine-promotion {
    margin-bottom: 70px;
  }
}

#homepage-medicine .ps-section__links {
  display: inline-block;
}

#homepage-medicine .ps-section__links li {
  display: inline-block;
  vertical-align: top;
  margin-right: 30px;
}

#homepage-medicine .ps-section__links li a {
  color: #666;
}

#homepage-medicine .ps-section__links li a:hover {
  color: #fcb800;
}

#homepage-medicine .ps-section__links li:last-child {
  margin-right: 0;
}

#homepage-medicine .ps-medicine-brands .ps-block--medicine-brand {
  margin-bottom: 50px;
}

#homepage-medicine .ps-medicine-brands .ps-section__content {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: -50px;
}

#homepage-medicine .ps-medicine-brands .ps-section__content > * {
  flex-basis: 100%;
  max-width: 50%;
}

@media screen and (min-width: 768px) {
  #homepage-medicine .ps-medicine-brands .ps-section__content > * {
    max-width: 33.33333%;
  }
}

@media screen and (min-width: 992px) {
  #homepage-medicine .ps-medicine-brands .ps-section__content > * {
    max-width: 20%;
  }
}

#homepage-medicine .ps-deal-of-day {
  margin-bottom: 40px;
}

#homepage-medicine .ps-deal-of-day .ps-product .ps-product__price {
  color: #690;
  font-size: 18px;
  font-weight: 600;
}

#homepage-medicine .ps-deal-of-day .ps-product .ps-product__price del {
  font-size: 0.8em;
  color: red;
  margin-right: 10px;
}

#homepage-medicine .ps-deal-of-day .ps-product .ps-product__price small {
  color: red;
}

@media screen and (min-width: 1200px) {
  #homepage-medicine .ps-deal-of-day {
    margin-bottom: 70px;
  }
}

#homepage-medicine .ps-medicine-brands,
#homepage-medicine .ps-medicine-testimonials {
  height: 100%;
}

#homepage-medicine .ps-medicine-brands-and-testimonial {
  margin-bottom: 50px;
}

#homepage-medicine .ps-medicine-top-categories .ps-block--category {
  position: relative;
  border-color: transparent;
}

#homepage-medicine .ps-medicine-top-categories .ps-block--category p {
  color: #000;
}

#homepage-medicine .ps-medicine-top-categories .ps-block--category:hover {
  border-color: #28afb1;
}

#homepage-medicine .ps-medicine-top-categories .ps-block--category:hover p {
  color: #28afb1;
}

#homepage-medicine .ps-medicine-top-categories .ps-section__column {
  padding: 0 10px;
}

#homepage-medicine .ps-medicine-top-categories .ps-section__content {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0 -10px;
}

#homepage-medicine .ps-medicine-top-categories .ps-section__content > * {
  flex-basis: 100%;
  max-width: 50%;
}

@media screen and (min-width: 768px) {
  #homepage-medicine .ps-medicine-top-categories .ps-section__content > * {
    max-width: calc(100% / 4);
  }
}

@media screen and (min-width: 1200px) {
  #homepage-medicine .ps-medicine-top-categories .ps-section__content > * {
    max-width: calc(100% / 7);
  }
}

#homepage-medicine .ps-medicine-description {
  padding: 40px 0;
}

#homepage-medicine .ps-medicine-description h4 {
  margin-bottom: 30px;
}

#homepage-medicine .ps-medicine-description p {
  margin-bottom: 20px;
}

#homepage-medicine .ps-medicine-description ul {
  margin: 0 0 30px;
  padding: 0;
}

#homepage-medicine .ps-medicine-description ul li {
  list-style-type: none;
  color: #000;
}

#homepage-medicine .ps-medicine-description ul li:before {
  content: '-';
  margin-right: 1em;
}

#homepage-medicine .ps-medicine-description figure {
  margin-bottom: 30px;
}

#homepage-medicine .ps-medicine-description figure figcaption {
  font-weight: 600;
  margin-bottom: 10px;
}

@media screen and (min-width: 1200px) {
  #homepage-medicine .ps-medicine-description {
    padding: 80px 0;
  }
}

#homepage-medicine .ps-main--medicine {
  padding: 26px 0;
  background-color: #eee;
}

#homepage-medicine .ps-main--medicine .ps-medicine-top-categories {
  margin-bottom: 40px;
}

@media screen and (min-width: 1366px) {
  #homepage-medicine .ps-main--medicine .ps-medicine-top-categories {
    margin-bottom: 70px;
  }
}

#homepage-medicine .ps-footer--medicine {
  background-color: #fff;
}

#homepage-medicine .ps-footer--medicine .form-group--nest {
  margin-bottom: 10px;
}

#homepage-medicine .ps-footer--medicine .ps-footer__widgets {
  display: block;
}

#homepage-medicine .ps-footer--medicine .ps-footer__widgets > * {
  max-width: 100%;
  flex-basis: 100%;
}

@media screen and (min-width: 1200px) {
  #homepage-medicine .ps-footer--medicine .ps-footer__widgets {
    display: flex;
  }
}

@media screen and (min-width: 1440px) {
  #homepage-medicine .ps-footer--medicine .ps-footer__widgets {
    display: flex;
    flex-flow: row nowrap;
  }
  #homepage-medicine .ps-footer--medicine .ps-footer__widgets > * {
    flex-basis: 100%;
  }
  #homepage-medicine .ps-footer--medicine .ps-footer__widgets .ps-footer__left {
    max-width: 420px;
  }
}

#homepage-medicine .ps-medicine-subscribe {
  margin-bottom: 40px;
  padding: 20px 20px;
}

#homepage-medicine .ps-medicine-subscribe .ps-section__right {
  display: flex;
  justify-content: space-between;
}

#homepage-medicine .ps-medicine-subscribe .ps-section__right img {
  max-height: 50px;
}

#homepage-medicine .ps-medicine-subscribe .ps-form--medicine-subscribe h3 {
  font-weight: 600;
  font-size: 24px;
}

#homepage-medicine .ps-medicine-subscribe .ps-form--medicine-subscribe .form-group {
  position: relative;
  display: flex;
  text-transform: uppercase;
}

#homepage-medicine .ps-medicine-subscribe .ps-form--medicine-subscribe .form-group button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 10px;
  border: none;
  color: #28afb1;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 600;
}

#homepage-medicine .ps-medicine-subscribe .ps-form--medicine-subscribe .form-group input {
  border: none;
  padding-left: 0;
  background-color: #fff;
  padding-left: 10px;
  border-bottom: 1px solid #eaeaea;
}

#homepage-medicine .ps-medicine-subscribe .ps-form--medicine-subscribe .form-group input:focus {
  background-color: transparent;
  border-bottom-color: #28afb1;
}

#homepage-medicine .ps-medicine-subscribe .ps-form--medicine-subscribe .ps-form__left {
  margin-bottom: 30px;
}

@media screen and (max-width: 1199px) {
  #homepage-medicine .ps-medicine-subscribe {
    background: #fff !important;
  }
}

@media screen and (min-width: 1200px) {
  #homepage-medicine .ps-medicine-subscribe {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 30px 45px;
    margin-bottom: 70px;
  }
  #homepage-medicine .ps-medicine-subscribe .ps-form--medicine-subscribe {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    max-width: 650px;
  }
  #homepage-medicine .ps-medicine-subscribe .ps-form--medicine-subscribe h3 {
    font-size: 30px;
  }
  #homepage-medicine .ps-medicine-subscribe .ps-form--medicine-subscribe > * {
    flex-basis: 100%;
  }
  #homepage-medicine .ps-medicine-subscribe .ps-form--medicine-subscribe .form-group input {
    background-color: transparent;
  }
  #homepage-medicine .ps-medicine-subscribe .ps-form--medicine-subscribe .ps-form__left {
    max-width: 160px;
    margin-bottom: 0;
  }
  #homepage-medicine .ps-medicine-subscribe .ps-form--medicine-subscribe .ps-form__right {
    padding-left: 30px;
  }
  #homepage-medicine .ps-medicine-subscribe .ps-section__right {
    max-width: 200px;
    display: block;
  }
  #homepage-medicine .ps-medicine-subscribe .ps-section__right a {
    display: block;
    margin-bottom: 20px;
    text-align: center;
  }
  #homepage-medicine .ps-medicine-subscribe .ps-section__right a:last-child {
    margin-bottom: 0;
  }
  #homepage-medicine .ps-medicine-subscribe .ps-section__left {
    flex-basis: 100%;
  }
}

@media screen and (min-width: 1440px) {
  #homepage-medicine .ps-medicine-subscribe {
    padding: 60px 90px;
  }
  #homepage-medicine .ps-medicine-subscribe .ps-form--medicine-subscribe .ps-form__right {
    padding-left: 90px;
  }
}

#homepage-medicine .ps-medicine--recommended-products {
  margin-bottom: 40px;
}

@media screen and (min-width: 1200px) {
  #homepage-medicine .ps-medicine--recommended-products {
    margin-bottom: 70px;
  }
}

#homepage-medicine .ps-footer--medicine .widget_contact-us .widget_content h3 {
  color: #28afb1;
}

#homepage-medicine .ps-footer--medicine .ps-form--newletter button {
  background-color: #28afb1;
  color: #fff;
}

#homepage-medicine .ps-footer--medicine .ps-form--newletter button:hover {
  background-color: #239a9c;
  color: #fff;
}
